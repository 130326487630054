export const useCurrentUID = () => {
  const pseudoLoginStore = usePseudoLogin();

  if (pseudoLoginStore.isPseduLogin == true) {
    return pseudoLoginStore.pseudoId;
  }

  const user = useCurrentUser();
  return user.value?.uid;
};
